
























































import { Vue, Component, Watch } from 'vue-property-decorator';
import {MotoristaService} from '@/core/services/geral';
import {Motorista} from '@/core/models/geral';
import { PageBase } from '@/core/models/shared';

@Component
export default class ListaMotorista extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Foto', value: 'usuario.foto', type: 'boolean' }, 
    { text: 'CPF', value: 'cpf' },
    { text: 'Nome', value: 'nome' },
    { text: 'Telefone', value: 'telefone1' },
    { text: 'Ativo', value: 'ativo', type: 'boolean' },
  ];
  item = new Motorista();
  service = new MotoristaService();
  sessionApp: any;

  filtro: any = {
    transportadoraId: this.app.transportadoraId,
  }

  @Watch('options', { deep: true })
  Atualizar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, "Usuario").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  mounted(){
    this.sessionApp = JSON.parse(localStorage.sessionApp);
  }

  AbrirDialogCadastro(item?: Motorista){
    if(item){
      this.service.ObterPorId(item.id, "Usuario").then(
        res=>{
          this.item = new Motorista(res.data)
          this.dialogCadastro = true
        },
        err=>{
          this.$swal("Aviso", err.response.data, "error");
        }
      )
    }
    else{
      this.item = new Motorista();
      this.item.empresaId = this.sessionApp.dados.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: Motorista){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.Excluir(item.id)
                  .then(res => {
                      if (res.status == 200){
                          return res.data;
                      }
                  },
                  err => {
                    if (err.response.status == 403){
                      this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                    }
                    else{
                      this.$swal('Aviso', err.response.data, 'error')
                    }
                  }
              )
          },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }
}

